import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import LocationCard from '../components/molecules/LocationCard';
import LocationDetails from '../components/organisms/LocationDetails';
import LocationGallery from '../components/organisms/LocationGallery';
import LocationHero from '../components/organisms/LocationHero';
import NewsletterSection from '../components/organisms/NewsletterSection';
import OtherCityLocations from '../components/organisms/OtherCityLocations';
import { PromoPopup } from '../components/organisms/PromoPopup';

const Location = ({
  data: {
    location,
    otherLocations,
    place,
    places,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  const otherCityLocations = otherLocations.nodes.filter(
    (l) =>
      l.location.city?.id === location.location.city?.id && l.id !== location.id
  );

  return (
    <>
      <Layout>
        <Seo title={location.title} />

        <LocationHero
          image={location.location.heroPhoto}
          comingSoonDate={location.location.comingSoonDate}
        />
        <LocationDetails
          name={location.title}
          description={location.location.description}
          timezone={location.location.timezone}
          address={location.location.address}
          place={place}
          comingSoon={location.location.comingSoon}
          comingSoonDate={location.location.comingSoonDate}
        />
        {location.location.gallery && (
          <LocationGallery images={location.location.gallery} />
        )}
        {otherCityLocations.length > 0 && !!location.location.city && (
          <OtherCityLocations city={location.location.city.title}>
            {otherCityLocations.map((l) => (
              <LocationCard
                key={l.title}
                name={l.title}
                slug={l.slug}
                illustration={l.location.illustration}
                photo={l.location.photo}
                address={l.location.address}
                comingSoon={l.location.comingSoon}
                comingSoonDate={l.location.comingSoonDate}
                timezone={l.location.timezone}
                place={places.nodes.find(
                  (p) => p.place_id === l.location.googlePlaceId
                )}
              />
            ))}
          </OtherCityLocations>
        )}

        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query LocationQuery($id: String!, $googlePlaceId: String) {
    location: wpLocation(id: { eq: $id }) {
      id
      title
      location {
        googlePlaceId
        description
        comingSoon
        comingSoonDate
        address
        timezone
        city {
          ... on WpCity {
            id
            title
          }
        }
        heroPhoto {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 1440, placeholder: BLURRED)
            }
          }
        }
        gallery {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 1440, placeholder: BLURRED)
            }
          }
        }
      }
    }
    otherLocations: allWpLocation {
      nodes {
        id
        title
        slug
        location {
          description
          address
          googlePlaceId
          timezone
          comingSoon
          comingSoonDate
          illustration
          photo {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1472, placeholder: BLURRED)
              }
            }
            altText
          }
          city {
            ... on WpCity {
              id
            }
          }
        }
      }
    }
    places: allGooglePlacesPlace {
      nodes {
        name
        place_id
        opening_hours {
          periods {
            open {
              day
              time
            }
            close {
              day
              time
            }
          }
        }
        formatted_address
      }
    }
    place: googlePlacesPlace(place_id: { eq: $googlePlaceId }) {
      name
      place_id
      opening_hours {
        periods {
          open {
            day
            time
          }
          close {
            day
            time
          }
        }
      }
      formatted_address
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Location;
