import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useScrollTo } from '../../hooks/use-scroll-to';
import LocationComingSoonImage from '../../images/location-coming-soon-big.jpg';
import RoundButtonBg from '../../images/round-button-bg.svg';
import WaveWhite from '../../images/wave-white.png';
import ArrowRightIcon from '../atoms/ArrowRightIcon';
import Image from '../atoms/Image';
import RunningDonutClock from '../atoms/RunningDonutClock';
import { format, parse } from 'date-fns';

interface LocationHeroProps {
  image: {
    altText: string;
    localFile: any;
  };
  comingSoonDate?: string;
}

const LocationHero = ({ image, comingSoonDate }: LocationHeroProps) => {
  const { scrollTo } = useScrollTo();
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [0, 675], [0, 100], { clamp: false });
  const y1 = useTransform(scrollY, [0, 675], [0, 300], { clamp: false });
  const opacity = useTransform(scrollY, [0, 675], [1, 0]);
  const x = useTransform(scrollY, [0, 5000], [0, -1000]);

  const handleScrollToContent = () => {
    scrollTo('location-details');
  };

  return (
    <section className="relative mt-[92px] overflow-hidden">
      {image ? (
        <>
          <motion.div className="absolute inset-0 z-0" style={{ y }}>
            <Image
              image={getImage(image?.localFile)}
              alt={image?.altText}
              className="w-full h-auto aspect-24/16"
            />
          </motion.div>
          <Image
            image={getImage(image?.localFile)}
            alt={image?.altText}
            className="w-full h-auto opacity-0 aspect-24/16"
          />
        </>
      ) : (
        <>
          <img
            src={LocationComingSoonImage}
            alt="Location coming soon"
            className="w-full h-auto"
          />
          <div className="absolute pb-10 lg:pb-20 inset-0 z-10 flex flex-col items-center justify-center text-white text-xl md:text-3xl lg:text-[86px] font-bold uppercase font-heading pointer-events-none">
            <RunningDonutClock className="w-12 h-auto mb-2 md:w-16 lg:mb-10 lg:w-20" />
            <h1 className="mx-auto text-center md:px-8 leading-[0.9]">
              {comingSoonDate
                ? `Opening ${format(
                    parse(comingSoonDate, 'dd/MM/yyyy', new Date()),
                    'MMMM do'
                  )}!`
                : 'Coming Soon'}
            </h1>
          </div>
        </>
      )}
      <div className="absolute left-0 right-0 hidden mt-8 bottom-16 lg:bottom-24 sm:block">
        <motion.button
          type="button"
          className="flex items-center justify-center w-12 h-12 mx-auto bg-center bg-no-repeat bg-contain rounded-full focus:outline-none"
          style={{ backgroundImage: `url(${RoundButtonBg})`, y: y1, opacity }}
          aria-label="Scroll to content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={handleScrollToContent}
        >
          <ArrowRightIcon className="transform rotate-90 animate-slide1" />
        </motion.button>
      </div>
      <div className="absolute left-0 right-0 h-8 overflow-hidden -bottom-px">
        <motion.div
          className="w-[10000px] h-full bg-repeat-x"
          style={{
            backgroundImage: `url(${WaveWhite})`,
            backgroundSize: '20% 100%',
            x: x,
          }}
        />
      </div>
    </section>
  );
};

export default LocationHero;
