import React, { PropsWithChildren } from 'react';

interface OtherCityLocationsProps {
  city: string;
}

const OtherCityLocations = ({
  city,
  children,
}: PropsWithChildren<OtherCityLocationsProps>) => (
  <section className="mb-32 overflow-hidden">
    <div className="container">
      <h2 className="max-w-sm mx-auto mb-12 text-center u-h3">
        See other locations in {city}
      </h2>
      <div className="flex flex-col justify-center space-y-8 lg:flex-row lg:space-y-0 lg:space-x-8">
        {children}
      </div>
    </div>
  </section>
);

export default OtherCityLocations;
