import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useNewsletter } from '../../hooks/use-newsletter';
import MultiWave from '../../images/multi-wave.png';
import BreadIcon from '../atoms/BreadIcon';
import Button from '../atoms/Button';
import ErrorIcon from '../atoms/ErrorIcon';
import OpeningHours from '../atoms/OpeningHours';
import { format, parse } from 'date-fns';
import { Link } from 'gatsby';

interface LocationDetailsProps {
  name: string;
  description: string;
  address?: string;
  timezone: string;
  place?: any;
  comingSoon?: boolean;
  comingSoonDate?: string;
}

const LocationDetails = ({
  name,
  description,
  address,
  timezone,
  place,
  comingSoon,
  comingSoonDate,
}: LocationDetailsProps) => {
  const { email, setEmail, success, error, validate, handleSubmit } =
    useNewsletter(name);

  return (
    <section id="location-details" className="py-24">
      <div className="container">
        <div className="max-w-[780px] mx-auto text-navy-blue">
          <h1 className="max-w-2xl mx-auto mb-4 text-center u-h2">
            {!!comingSoon && (
              <div className="font-sans text-xl font-bold leading-8 normal-case">
                {comingSoonDate
                  ? `Celebrate our Grand Opening ${format(
                      parse(comingSoonDate, 'dd/MM/yyyy', new Date()),
                      'MMMM do'
                    )}!`
                  : 'coming soon!'}
              </div>
            )}
            {name}
          </h1>
          {comingSoon ? (
            <div>
              <div className="mb-6 text-base leading-6 text-center">
                Enter your email below to stay in the loop with all updates
                regarding our {name} shop and gain entry for exclusive VIP
                invites and early access events.
              </div>
            </div>
          ) : (
            <div
              className="text-base leading-6 text-center u-prose"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}

          {!comingSoon && (
            <>
              <div className="flex flex-col justify-center py-4 mb-6 border-t border-b md:flex-row border-faux-light">
                <div className="pb-4 pr-6 md:pb-0">
                  {place ? (
                    <div
                      className="text-xl"
                      dangerouslySetInnerHTML={{
                        __html: address
                          ? `${address}`.replace('\n', '<br />')
                          : '',
                      }}
                    />
                  ) : (
                    <div className="text-xl">
                      Subscribe for grand opening details and be automatically
                      added for VIP events + early access.
                    </div>
                  )}
                </div>
                {place?.opening_hours ? (
                  <div className="pt-4 text-xl border-t md:border-t-0 md:border-l border-faux-light md:pt-0 md:pl-7">
                    <OpeningHours place={place} timezone={timezone} />
                  </div>
                ) : (
                  <div className="md:w-[150px] flex-shrink-0 border-t md:border-t-0 md:border-l border-faux-light text-right text-xl font-bold pt-4 md:pt-0">
                    coming soon!
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-5 text-center align-center sm:justify-center sm:flex-row">
                <Button to="https://order.saltydonut.com/">Order now</Button>
                <Button to="/careers" variant="outline">
                  View positions &amp; apply
                </Button>
              </div>
            </>
          )}

          {comingSoon && (
            <>
              <div
                className={`pt-4 border-t border-faux-light ${
                  !!comingSoon ? 'mb-10' : 'mb-24'
                }`}
              >
                <AnimatePresence exitBeforeEnter>
                  {success ? (
                    <motion.div
                      key="success"
                      className="flex items-center space-x-6 text-2xl font-bold uppercase font-heading text-navy-blue lg:pl-9"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <BreadIcon />
                      <div>THANKS FOR SIGNING UP!</div>
                    </motion.div>
                  ) : (
                    <motion.form
                      key="form"
                      action=""
                      onSubmit={handleSubmit}
                      className="w-full"
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <input
                        type="hidden"
                        name="location_coming_soon"
                        value={name}
                      />
                      <label
                        htmlFor="email"
                        className={`u-label ${error && 'u-label--error'}`}
                      >
                        Your email address
                      </label>
                      <div className="items-center w-full lg:space-x-4 lg:flex lg:flex-row lg:w-auto">
                        <div className="relative inline-flex flex-grow w-full lg:w-auto">
                          <input
                            className={`u-input ${
                              error && 'u-input--error'
                            } flex-grow`}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Type your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => validate()}
                          />
                          <AnimatePresence>
                            {error && (
                              <ErrorIcon className="absolute right-0 mr-4 -mt-3 pointer-events-none top-1/2" />
                            )}
                          </AnimatePresence>
                        </div>
                        <AnimatePresence>
                          {error && (
                            <div className="lg:hidden">
                              <motion.div
                                className="text-red-accessibility pt-0.5"
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                              >
                                {error}
                              </motion.div>
                            </div>
                          )}
                        </AnimatePresence>
                        <Button
                          type="submit"
                          className="w-full mt-4 lg:w-auto lg:mt-0"
                        >
                          Subscribe
                        </Button>
                      </div>
                      <AnimatePresence>
                        {error && (
                          <motion.div
                            className="text-red-accessibility pt-0.5"
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                          >
                            <div className="hidden lg:block">{error}</div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                      <p className="mt-4 text-navy-blue">
                        By signing up, you agree to The Salty's{' '}
                        <a
                          href="https://lunchbox.io/privacy"
                          target="_blank"
                          rel="noreferrer noopener"
                          className="underline"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </motion.form>
                  )}
                </AnimatePresence>
              </div>
            </>
          )}

          {!!comingSoon && (
            <div className="mb-16 text-center">
              <Button to="/careers" variant="outline">
                View positions &amp; apply
              </Button>
            </div>
          )}
        </div>
      </div>

      {!!comingSoon && (
        <div
          className="hidden w-full h-12 lg:block"
          style={{
            backgroundImage: `url(${MultiWave})`,
            backgroundSize: '100% 100%',
          }}
        />
      )}
    </section>
  );
};

export default LocationDetails;
